import { API_BASE_URL } from '../configs/AppConfig';
import axios from 'axios';
import fetch from 'auth/FetchInterceptor'
// create function that fetch current user
export const currentMe = async () => {
    const response = await fetch({
        url: '/auth/me',
        method: 'get'
    })
    return response.data;
}

export const formatDate = (dateString, onlyDate = false) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    if (onlyDate) {
        return `${day}/${month}/${year}`;
    }
    return `${day}/${month}/${year} ${hours}:${minutes}`;
};

// create function that fetch users from the server
export const fetchUsers = async () => {
    const response = await fetch({
        url: '/users',
        method: 'get'
    })
    return response.data;
}

export const fetchNotifications = async () => {
    try {
        const response = await fetch({
            url: `/notification/admin`,
            method: 'get',
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const createUser = async (user) => {
    try {
        //const response = await axios.post(`${API_BASE_URL}/users?userType=${user?.role}`, user);
        let modified = user;
        modified.admin = true

        const response = await fetch({
            url: `/users/admincreate?userType=${user?.role}`,
            method: 'post',
            data: modified
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const getUserById = async (id) => {
    try {
        //const response = await axios.post(`${API_BASE_URL}/users?userType=${user?.role}`, user);
        const response = await fetch({
            url: `/users/${id}`,
            method: 'get',
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }

}

export const updateUser = async (data) => {
    try {
        const response = await fetch({
            url: `/users/updateUserType`,
            method: 'put',
            data,
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const verifyUser = async (data) => {
    try {
        const response = await fetch({
            url: `/users/verifyUser`,
            method: 'put',
            data,
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const toggleUserStatus = async (id) => {
    try {
        const response = await fetch({
            url: `/users/toggle-activation-status/${id}`,
            method: 'post',
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const updateClientProfile = async (id, user) => {
    try {
        //const response = await axios.put(`${API_BASE_URL}/profile/${id}`, user);
        const response = await fetch({
            url: `/profile`,
            method: 'put',
            data: user
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const updateModelProfile = async (id, user) => {
    try {
        //const response = await axios.put(`${API_BASE_URL}/profile/${id}`, user);
        const response = await fetch({
            url: `/profile/member`,
            method: 'put',
            data: user
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const updateModelPopularFlag = async (_id) => {
    try {
        const response = await fetch({
            url: `/users/popular`,
            method: 'post',
            data: { _id }
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const deleteProfileUser = async (profileId) => {
    try {
        const response = await fetch({
            url: `/users/${profileId}`,
            method: 'delete'
        })

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const fetchModels = async () => {
    const response = await axios.get(`${API_BASE_URL}/models`);
    return response.data;
}
// Perform API call here with formData
// Example:
// fetch('http://localhost:8000/profile/member', {
//   method: 'POST',
//   body: formData
// })
// .then(response => response.json())
// .then(data => console.log(data))
// .catch(error => console.error('Error:', error));


export const createModel = async (model) => {
    try {
        //const response = await axios.post(`${API_BASE_URL}/profile/member`, model);
        const response = await fetch({
            url: '/profile/member',
            method: 'post',
            data: model
        })

        return response.data;
    } catch (error) {
        console.log(error);
    }
}
export const createClientProfile = async (client) => {
    try {
        //const response = await axios.post(`${API_BASE_URL}/profile/client`, client);
        const response = await fetch({
            url: '/profile',
            method: 'post',
            data: client
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}


export const getAllProfiles = async () => {
    //const response = await axios.get(`${API_BASE_URL}/profile/admin/client`);
    const response = await fetch({
        url: '/profile/admin/client',
        method: 'get'
    })
    return response.data;
}

export const getAllModelsProfiles = async () => {
    //const response = await axios.get(`${API_BASE_URL}/profile/admin/member`);
    const response = await fetch({
        url: '/profile/admin/member',
        method: 'get'
    })
    return response.data;
}

export const getAllBlogs = async (sortBy = '') => {
    const response = await fetch({
        url: `/blog${sortBy && `?sortBy=${sortBy}`}`,
        method: 'get',
    })
    return response.data;
}

export const getBlogBySlug = async (slug) => {
    const response = await fetch({
        url: `/blog/${slug}`,
        method: 'get',
    })
    return response.data;
}

export const postNewsBlog = async (data) => {
    const response = await fetch({
        url: '/blog',
        method: 'post',
        data: data
    })
    return response.data;
}

export const updateBlog = async (slug, data) => {
    const response = await fetch({
        url: `/blog/${slug}`,
        method: 'patch',
        data: data
    })
    return response.data;
}

export const deleteBlog = async (slug) => {
    const response = await fetch({
        url: `/blog/${slug}`,
        method: 'delete',
    })
    return response.data;
}

export const getBlogAssetsByAuthor = async (author, type) => {
    const response = await fetch({
        url: `/blog/${author}/${type}`,
        method: 'get',
    })
    return response.data;
}

export const getGallery = async () => {
    const response = await fetch({
        url: `/blog/gallery/list`,
        method: 'get'
    })
    return response.data;
}

export const uploadGallery = async (data) => {
    const response = await fetch({
        url: `/blog/galleryupload`,
        method: 'post',
        data: data
    })
    return response.data;
}

export const deleteGallery = async (data) => {
    const response = await fetch({
        url: `/blog/gallery/delete`,
        method: 'delete',
        data: data
    })
    return response.data;
}

export const fetchAllBookings = async () => {
    const response = await fetch({
        url: '/bookings/all',
        method: 'get'
    })
    return response.data;
}


export const fetchsettings = async () => {
    const response = await fetch({
        url: '/settings',
        method: 'get'
    })
    return response.data;
}

export const updatesettings = async (data) => {
    const response = await fetch({
        url: '/settings',
        method: 'patch',
        data: data
    })
    return response.data;
}


export const fetchAllReports = async () => {
    const response = await fetch({
        url: '/users/report/all',
        method: 'get'
    })
    return response.data;
}

export const fetchAllTransactions = async () => {
    const response = await fetch('/coins/transactions');
    return response.data;
};

export const fetchAllPayouts = async () => {
    const response = await fetch('/coins/payouts');
    return response.data;
};

export const fetchAllPackages = async () => {
    const response = await fetch('/coins/packages');
    return response.data;
};

export const addPackage = async (data) => {
    const response = await fetch({
        url: `/coins/packages`,
        method: 'post',
        data: data
    })
    return response.data;
};
export const deletePackage = async (id) => {
    await fetch({
        url: `/coins/packages/${id}/delete`,
        method: 'delete',
    })
};

export const updateCreditNote = async (data) => {
    const response = await fetch({
        url: 'coins/updatePayouts',
        method: 'put',
        data
    });
    return response.data;
};

export const fetchAllInvitations = async () => {
    const response = await fetch({
        url: '/users/invitations/all',
        method: 'get'
    })
    return response.data;
};

export const fetchAllVideoCalls = async () => {
    const response = await fetch({
        url: '/video-call/all/rooms',
        method: 'get'
    })
    return response.data;
};

export const deleteRoom = async (id) => {
    const response = await fetch({
        url: `/video-call/${id}`,
        method: 'delete'
    })
    return response.data;
};

export const deleteMessage = async (id) => {
    const response = await fetch({
        url: `/video-call/message/${id}`,
        method: 'delete'
    })
    return response.data;
};

export const fetchAllOnlineUsers = async () => {
    const response = await fetch({
        url: '/video-call/all/connectedusers',
        method: 'get'
    })
    return response.data;
};

export const fetchAllimages = async () => {
    const response = await fetch({
        url: '/profile/admin/allimages',
        method: 'get'
    })
    return response.data;
};
export const imageDelete = async (id) => {
    const response = await fetch({
        url: `/profile/admin/deleteteimage/${id}`,
        method: 'get'
    })
    return response.data;
};
export const updateSuccessStory = async (slug, data) => {
    const response = await fetch({
        url: `/blog/memberStory/${slug}`,
        method: 'post',
        data: data
    })
    return response.data;
}

export const acceptBooking = async (slug) => {
    const response = await fetch({
        url: `/bookings/accept/${slug}`,
        method: 'get',
    })
    return response.data;
}

export const rejectBooking = async (slug) => {
    const response = await fetch({
        url: `/bookings/reject/${slug}`,
        method: 'get',
    })
    return response.data;
}

export const deletBooking = async (slug) => {
    const response = await fetch({
        url: `/bookings/delete/${slug}`,
        method: 'delete',
    })
    return response.data;
}

export const updateNotification = async (id) => {
    const response = await fetch({
        url: `/notification/read/${id}`,
        method: 'get',
    })
    return response.data;
};
